<template>
  <div class="myBank">
      <ul >
         <li v-for="item in ListData" class="orange">
        <!-- <li class="orange" > -->
          <div>
            <p>{{item.name}}</p>
            <p>{{item.bankCard | bank}}</p>
          </div>
          <span @click="bankInfo(item.bankCard,item.phone)">查看</span>
        </li>
      </ul>
    <div class="noBank">
      <router-link :to="{path:'realNameBank'}">
        <img src="../assets/img/add.png" alt="">
        <p>添加银行卡</p>
      </router-link>
    </div>
  </div>
</template>
<script>
import { queryUserBankCard } from "../services/api";
import basic from "../utils/basic";
import { encryptRSA, decryptRSA, encryptAES, decryptAES } from "../utils/encryption";
import { Dialog } from 'vant';

export default {
  data() {
    return {
      ListData: [],
    };
  },
  created: function() {
    var _this = this;
    _this.queryUserBankCard();

  },
  methods: {
     async queryUserBankCard() {
      const datas = await queryUserBankCard({});
      if (datas.res_code == 1) {
         let encrypted = datas.data.encrypted; 
          let data = datas.data.msg;
          let aseKey = decryptRSA(encrypted); //RSA解密
          let res = JSON.parse(decryptAES(data, aseKey)); //AES解密
          this.ListData=res;
          console.log(res); 
      }
    },
    bankInfo(bandCard,phone){
      let msg=bandCard+"<br />"+phone;
      Dialog.alert({
        title: '温馨提示',
        confirmButtonText:'我知道了',
        message:msg,
      })
      .then(() => {
        // on confirm
      })
    },
  },
  filters: {
    bank: function(bankAccount) {
     let reg = /^(.{4})(?:\d+)(.{4})$/
     let str = bankAccount.replace(reg, "**** **** **** $2");
     return str;
    },
  }
};
</script>
<style lang="scss" scoped>
.myBank{
  ul{
    .orange{background: orange;}
    .blue{background:#5757dd;}
    li{
      height:7rem;color:#fff;border-radius:0.25rem;margin:1rem;
      div{font-size:1rem;display: inline-block;padding:1.5rem 0 0 2rem;width:68%;line-height: 2rem;}
      span{
        display: inline-block;text-align: right;color:#fff;border:0.01rem solid #fff;border-radius: 0.25rem;
        padding: 0.5rem 1rem;
      }
    }
  }
  .noBank {
      min-height: 100%;
      position: relative;
      padding-bottom: 3.05rem;
      box-sizing: border-box;
      text-align: center;
      margin: 1rem;
      padding: 1rem;
      border:0.01rem dashed #aaa;
      border-radius: 0.25rem;
      p{color: #aaa;}
      img{padding-bottom: 1rem;width: 3rem;}
  }
}
</style>
